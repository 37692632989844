/// <reference path="../../../globals.d.ts" />
import React from 'react';
import Script from 'next/script';
import { getCookie } from '@ui/hooks/useCookie';
import env from '@ui/env';
import { GetCart } from '@client-shopify/gql/storefront/api/queries/GetCart';

type SSIntelliSuggestCartViewProps = {
  cartQueryResults: Awaited<ReturnType<typeof GetCart>> | undefined;
};

const SSIntelliSuggestCartView = ({ cartQueryResults }: SSIntelliSuggestCartViewProps): React.ReactElement => (
  <Script
    type="text/plain"
    data-cookieconsent="preferences"
    src="https://cdn.searchspring.net/intellisuggest/is.min.js"
    id="searchspring-intellisuggest-cart-view"
    onLoad={() => {
      const shopperID = getCookie('ssUserId');
      const seed: Array<string | undefined> = [];
      if (!cartQueryResults || !cartQueryResults.cart) return;
      if (!cartQueryResults.cart.cartLines || !cartQueryResults.cart.cartLines.length) return;
      cartQueryResults.cart.cartLines.forEach((product) => {
        seed.push(product.sku ?? '');
      });

      IntelliSuggest.init({
        siteId: env.NEXT_PUBLIC_SEARCHSPRING_SITEID,
        context: 'Basket',
        seed,
      });
      IntelliSuggest.setShopperId(shopperID as string);

      cartQueryResults.cart.cartLines.forEach((product) => {
        IntelliSuggest.haveItem({
          sku: product.sku ?? '',
          qty: product.quantity.toString(),
          price: product.costPerQuantity.amount ?? '',
        });
      });

      IntelliSuggest.inBasket({});
    }}
  />
);

export default SSIntelliSuggestCartView;
