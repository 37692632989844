import {
  innerScriptFuncAu,
  innerScriptFuncNz,
  innerScriptFuncGb,
  innerScriptFuncUs,
} from '@content/data/forter.ts';
import { cookieCategory, CookieScripts } from '@ui/hooks/useCookieConsent.tsx';

interface RegionSpecificookieScripts {
  [key: string]: CookieScripts;
};

export const cookieScriptAllRegions: CookieScripts = {
  marketing: [
    {
      id: "elevar-datalayer",
      strategy: "afterInteractive",
      type: "module",
      category: cookieCategory.marketing,
      content: `if (typeof window !== "undefined") {
          function setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
          }
          function getCookie(name) {
            const nameEQ = name + "=";
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') c = c.substring(1, c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
          }
          window.ElevarUserIdFn = () => {
            let userId = getCookie('user_id');
            if (!userId) {
              userId = crypto.randomUUID();
              setCookie('user_id', userId, 365);
            }
            return userId;
          };
        }`,
    },
    {
      id: "fb-pixel",
      strategy: "afterInteractive",
      category: cookieCategory.marketing,
      content: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '395614233924008');
        fbq('track', 'PageView');
      `
    }
  ],
  preferences: [],
  statistics: []
};

export const cookieScriptSpecificRegions: RegionSpecificookieScripts = {
  AU: {
    marketing: [
      {
        id: "elevar-gtm-setup",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KW2CBSMQ');`
      },
      {
        id: "tiktok-track",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `!function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
        var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
        ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
    
    
          ttq.load('CQ3NFD3C77UAOAV5CPV0');
          ttq.page();
        }(window, document, 'ttq');`
      },
      {
        id: "yotpo-datalayer",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: 'window.wtba = window.wtba || []'
      },
      // emarsysInnerScarabScriptFunc
      {
        id: "emarsys-scarab",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `(function(id) {
          if (document.getElementById(id)) return;
          var js = document.createElement('script'); js.id = id;
          js.src = '//cdn.scarabresearch.com/js/17309B80C7260A5C/scarab-v2.js';
          var fs = document.getElementsByTagName('script')[0];
          fs.parentNode.insertBefore(js, fs);
        })('scarab-js-api')`
      },
      // emarsysInnerScriptFunc
      {
        id: "emarsys-webextend",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `var WebEmarsysSdk = WebEmarsysSdk || [];
          WebEmarsysSdk.push(['init', {
            enableLogging: true,
            applicationCode: 'EMS21-B5A60',
            safariWebsitePushID: 'web.com.hellomolly.au',
            defaultNotificationTitle: 'Hello Molly',
            defaultNotificationIcon: 'https://link-au.hellomolly.com/custloads/1042716357/md_20356.png',
            autoSubscribe: false,
            serviceWorker: {
              url: 'apps/ems/web-emarsys-sdk/4.3.1/service-worker.js',
              applicationServerPublicKey: 'BHRReaNxUTa3BH4Ibm--ihyOjjlCLUPMXuRkQJIkEndK8Mvld6eJ7PzqWnsAILAzL1sYkd2B8MR2gXFMlcrIHjg'
            }
          }])`
      },
      /*
      @todo issue with import token - need to check why
      {
        id: "elevar-config",
        strategy: "beforeInteractive",
        type: "module",
        category: cookieCategory.marketing,
        content: `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/75356db2c6add315546bf0be3f3d848ccb31461b/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
      },
      */
      {
        id: "85f15f8d83b8",
        type: "text/javascript",
        strategy: "lazyOnload",
        category: cookieCategory.marketing,
        content: innerScriptFuncAu
      }
    ],
    preferences: [],
    statistics: [
      /*
      {
        id: "dynatrace",
        type: "src",
        category: cookieCategory.statistics,
        strategy: "beforeInteractive",
        content: 'https://js-cdn.dynatrace.com/jstag/16c6b3f0987/bf55917khu/14f8513e40fed979_complete.js'
      }
      */
    ]
  },
  US: {
    marketing: [
      /*
      @todo issue with import token - need to check why
      {
        id: "elevar-config",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/f22b9eaaeb17824eee1d11189ea640a5f8cf24a1/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`
      },
       */
      {
        id: "elevar-gtm-setup",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NJWP92Q7');`
      },
      {
        id: "tiktok-track",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
          var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
          ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
      
      
            ttq.load('CQ3NFD3C77UAOAV5CPV0');
            ttq.page();
          }(window, document, 'ttq');`
      },
      {
        id: "yotpo-datalayer",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: 'window.wtba = window.wtba || []'
      },
      // emarsysInnerScarabScriptFunc
      {
        id: "emarsys-scarab",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `(function(id) {
          if (document.getElementById(id)) return;
          var js = document.createElement('script'); js.id = id;
          js.src = '//cdn.scarabresearch.com/js/1D4362B7C87226BD/scarab-v2.js';
          var fs = document.getElementsByTagName('script')[0];
          fs.parentNode.insertBefore(js, fs);
          })('scarab-js-api');`
      },
      // emarsysInnerScriptFunc
      {
        id: "emarsys-webextend",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `var WebEmarsysSdk = WebEmarsysSdk || [];
          var WebEmarsysSdk = WebEmarsysSdk || [];
          WebEmarsysSdk.push(['init', {
            enableLogging: true,
            applicationCode: 'EMS45-17971',
            safariWebsitePushID: 'web.com.hellomolly.www',
            defaultNotificationTitle: 'Hello Molly',
            defaultNotificationIcon: 'https://link-us.hellomolly.com/custloads/1049686944/md_20407.png',
            autoSubscribe: false,
            serviceWorker: {
              url: 'apps/ems/web-emarsys-sdk/4.3.1/service-worker.js',
              applicationServerPublicKey: 'BKXuOtIPqSlyixnIbSLjrov19Vo14LmZr63vMftVoiMI5Y8BS9oC-rGmZHBpbvGTp77ZkKE19K9MOkZZu2Zb734'
            }
          }])`
      },
      {
        id: "85f15f8d83b8",
        type: "text/javascript",
        strategy: "lazyOnload",
        category: cookieCategory.marketing,
        content: innerScriptFuncUs
      }
    ],
    preferences: [],
    statistics: [
      /*
      {
        id: "dynatrace",
        type: "src",
        category: cookieCategory.statistics,
        strategy: "beforeInteractive",
        content: 'https://js-cdn.dynatrace.com/jstag/16c6b3f0987/bf55917khu/b2ea1b29a334b39e_complete.js'
      }
       */
    ]
  },
  GB: {
    marketing: [
      /*
      @todo issue with import token - need to check why
      {
        id: "elevar-config",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/8d14f1b9545798d871b2f32ffe50fad0ddf2e58b/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;
          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
      },
       */
      {
        id: "elevar-gtm-setup",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KDQBT7Q2');`
      },
      {
        id: "tiktok-track",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
          var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
          ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
      
      
            ttq.load('CQ3NFD3C77UAOAV5CPV0');
            ttq.page();
          }(window, document, 'ttq');`
      },
      {
        id: "yotpo-datalayer",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: 'window.wtba = window.wtba || []'
      },
      // emarsysInnerScarabScriptFunc
      {
        id: "emarsys-scarab",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `
          (function(id) {
            if (document.getElementById(id)) return;
            var js = document.createElement('script'); js.id = id;
            js.src = '//cdn.scarabresearch.com/js/17309B80C7260A5C/scarab-v2.js';
            var fs = document.getElementsByTagName('script')[0];
            fs.parentNode.insertBefore(js, fs);
          })('scarab-js-api');`
      },
      // emarsysInnerScriptFunc
      {
        id: "emarsys-webextend",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `var WebEmarsysSdk = WebEmarsysSdk || [];
          WebEmarsysSdk.push(['init', {
            enableLogging: true,
            applicationCode: 'EMS21-B5A60',
            safariWebsitePushID: 'web.com.hellomolly.au',
            defaultNotificationTitle: 'Hello Molly',
            defaultNotificationIcon: 'https://link-au.hellomolly.com/custloads/1042716357/md_20356.png',
            autoSubscribe: false,
            serviceWorker: {
              url: 'apps/ems/web-emarsys-sdk/4.3.1/service-worker.js',
              applicationServerPublicKey: 'BHRReaNxUTa3BH4Ibm--ihyOjjlCLUPMXuRkQJIkEndK8Mvld6eJ7PzqWnsAILAzL1sYkd2B8MR2gXFMlcrIHjg'
            }
        }])`
      },
      {
        id: "85f15f8d83b8",
        type: "text/javascript",
        strategy: "lazyOnload",
        category: cookieCategory.marketing,
        content: innerScriptFuncGb
      }
    ],
    preferences: [],
    statistics: []
  },
  NZ: {
    marketing: [
      /*
      @todo issue with import token - need to check why
      {
        id: "elevar-config",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/24c2a44aa362a2406ae32716ac3fbbeae6abc70a/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
      },
       */
      {
        id: "elevar-gtm-setup",
        strategy: "afterInteractive",
        category: cookieCategory.marketing,
        content: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MB4KRWXF');`
      },
      {
        id: "85f15f8d83b8",
        type: "text/javascript",
        strategy: "lazyOnload",
        category: cookieCategory.marketing,
        content: innerScriptFuncNz
      }
    ],
    preferences: [],
    statistics: [
      /*
      {
        id: "dynatrace",
        type: "src",
        category: cookieCategory.statistics,
        strategy: "beforeInteractive",
        content: 'https://js-cdn.dynatrace.com/jstag/16c6b3f0987/bf55917khu/1d5ff66c3d226fff_complete.js'
      }
       */
    ]
  }
};