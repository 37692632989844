import { ReactElement, useEffect, useState } from 'react';
import Script from 'next/script';
import { StaticContentType } from '@ui/providers/static-content-provider.tsx';

export interface CookieCategories {
  id: string;
  category: string;
  strategy?: 'beforeInteractive' | 'afterInteractive' | 'lazyOnload' | 'worker';
  content: string | keyof StaticContentType;
  type?: string;
};

export interface CookieScripts {
  marketing: CookieCategories[];
  preferences: CookieCategories[];
  statistics: CookieCategories[];
};

export const cookieCategory = {
  marketing: "marketing",
  preferences: 'preferences',
  statistics: 'statistics'
};

export interface CookieConsentDefaults {
  marketing: boolean;
  preferences: boolean;
  statistics: boolean;
};

export function BuildCookieScripts(cookies: CookieCategories[]): ReactElement[] {
  return cookies.map((script: CookieCategories) =>
    script.type === "src" ?
      <Script
        key={script.id}
        id={script.id}
        strategy={script.strategy}
        src={script.content ?? ''}
      />
    :
      <Script
        key={script.id}
        id={script.id}
        strategy={script.strategy}
        dangerouslySetInnerHTML={{
          __html: script.content ?? ''
        }}
      />
  );
};

export const useCookieConsent = (): CookieConsentDefaults  => {
  const [cookieConsentSettings, setCookieConsentSettings] = useState<CookieConsentDefaults>({
    marketing: false,
    preferences: false,
    statistics: false,
  });

  useEffect(() => {
    /**
     * Cookiebot callback function for when the consent banner is either accepted or declined
     * @param event
     * @constructor
     */
    function CookiebotCallback_OnLoad(event: any) {

      // Get Cookiebot consent values and attach the corresponding scripts baesd on user selection
      const { Cookiebot } = event.target;

      setCookieConsentSettings({
        ...cookieConsentSettings,
        marketing: Cookiebot.consent.marketing,
        preferences: Cookiebot.consent.preferences,
        statistics: Cookiebot.consent.statistics
      });
    };
    window.addEventListener("CookiebotOnLoad", CookiebotCallback_OnLoad);

    return () => {
      window.removeEventListener("CookiebotOnLoad", CookiebotCallback_OnLoad);

    };
  }, []);

  return cookieConsentSettings;
};