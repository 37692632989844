import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const useCookie = (cookieName: string): string => {
  const [cookie, setCookie] = useState<string>('');

  useEffect(() => {
    const cookieValue = getCookie(cookieName);
    setCookie(cookieValue);
  }, [cookieName]);

  return cookie;
};

export const getCookie = (cookieName: string): string => {
  return Cookies.get(cookieName) || '';
};

interface CookieOptions {
  expires?: Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
}

export const setCookie = (key: string, value: string | undefined, options: CookieOptions = {}): void => {
  if (!key || value === undefined) {
    console.error('Key and value must be provided to set a cookie.');
    return;
  }

  if (!getCookie(key)) {
    let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    if (options.expires) {
      cookieString += `; expires=${options.expires.toUTCString()}`;
    }

    if (options.path) {
      cookieString += `; path=${options.path}`;
    }

    if (options.domain) {
      cookieString += `; domain=${options.domain}`;
    }

    if (options.secure) {
      cookieString += '; secure';
    }

    if (options.httpOnly) {
      cookieString += '; HttpOnly';
    }

    if (options.sameSite) {
      cookieString += `; samesite=${options.sameSite}`;
    }
    document.cookie = cookieString;
  }
};
