import { useEffect } from 'react';

import staticContentRegion from '@ui/helpers/staticContentRegion';
import env from '@ui/env';

const content = staticContentRegion(env.NEXT_PUBLIC_REGION);

declare global {
  interface Window {
    ScarabQueue: any[];
  }
}
export function useScarabQueue() {
  const emarsysInnerScarabScriptFunc = content['Tag.emarsysInnerScarabScriptFunc'];
  const emarsysInnerScriptFunc = content['Tag.Emarsys.WebExtend'];

  const addScript = (id: string, scriptContent: string) => {
    if (document.getElementById(id)) return;
    const script = document.createElement('script');
    script.id = id;
    script.innerHTML = scriptContent;
    document.head.appendChild(script);
  };
  // Initialize the ScarabQueue, which is used to send data to Emarsys.
  // Initialize refs for cart, productView, categoryView, searchTerm, email.
  useEffect(() => {
    window.ScarabQueue = window.ScarabQueue || [];
    if (!emarsysInnerScarabScriptFunc || !emarsysInnerScriptFunc) return;
    addScript('scarab-js-api', emarsysInnerScarabScriptFunc);
    addScript('web-emarsys-sdk', emarsysInnerScriptFunc);
  }, []);
  return {};
}
