import React, { useEffect, useMemo, useRef } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { NextRouter, useRouter } from 'next/router';
import { MantineProvider } from '@mantine/core';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import { themeOverride } from '@ui/components/theme';
import {Header, StoreLocator} from '@ui/components/features';
import { NextPageWithHeader } from '@ui/types/pages';
import { ReactQueryProvider, StaticContentProvider } from '@repo/ui/providers';
import { Error, LocationDialog, NewsletterDialog } from '@ui/components/shared';
import { useOs } from '@mantine/hooks';
import { useRouteProgress } from '@ui/hooks/useRouteProgress';
import { useAuthRedirect } from '@ui/hooks/useAuthRedirect';
import staticContentRegion from '@ui/helpers/staticContentRegion';
import env from '@ui/env';
import '@repo/ui/styles.css';
import '../styles/global.css';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import { getUpdatedPath } from '@repo/ui/helpers';
import { useScarabQueue } from '@ui/hooks/useScarabQueue';
import { useTolstoy } from '@ui/hooks/useTolstoy';
import { latoFont } from '@ui/helpers/fontHelper';
import { Toaster } from '@ui/components/core/toaster';
import dynamic from "next/dynamic";
import { register } from 'swiper/element/bundle';
import { BuildCookieScripts, useCookieConsent } from '@ui/hooks/useCookieConsent';
import { cookieScriptAllRegions, cookieScriptSpecificRegions } from '@ui/cookies/cookieScripts.ts';

register();

type AppPropsWithHeader = AppProps & {
  Component: NextPageWithHeader;
};

const Footer = dynamic(() => import('@ui/components/features/global/Footer/Footer.tsx'));

const generateTitle = (router: NextRouter) => {
  if (router.pathname.includes('product') || router.pathname.includes('collections')) {
    return;
  }

  let storeTitle = 'Hello Molly';

  switch (env.NEXT_PUBLIC_REGION) {
    case 'AU':
      storeTitle = storeTitle + ' AU';
      break;
    case 'NZ':
      storeTitle = storeTitle + ' NZ';
      break;
    case 'GB':
      storeTitle = storeTitle + ' UK';
      break;
    default:
      storeTitle = storeTitle + ' US';
      break;
  }

  if (router.pathname.includes('pages')) {
    if (router.pathname.includes('faq')) {
      return `${storeTitle} | FAQ`;
    }
    return `${storeTitle} | ${router.pathname.split('/').slice(-1).toString().toLocaleUpperCase().replace('-', ' ')}`;
  }

  return `${storeTitle} | Fashion`;
};

const content = staticContentRegion(env.NEXT_PUBLIC_REGION);

const allscripts = cookieScriptAllRegions;

const regionScripts = cookieScriptSpecificRegions;

export default function HelloMollyApp({ Component, pageProps }: AppPropsWithHeader) {
  useElevarDataLayer();
  useScarabQueue();
  useAuthRedirect();
  useTolstoy();

  const { marketing, preferences, statistics } = useCookieConsent();

  // Load in all consented scripts
  const setCookiesScripts = useMemo(() => {
    return BuildCookieScripts(
      [
        ...marketing ? allscripts.marketing.concat(regionScripts[env.NEXT_PUBLIC_REGION].marketing) : [],
        ...preferences ? allscripts.preferences.concat(regionScripts[env.NEXT_PUBLIC_REGION].preferences) : [],
        ...statistics ? allscripts.statistics.concat(regionScripts[env.NEXT_PUBLIC_REGION].statistics) : []
      ]
    );
  }, [marketing, preferences, statistics]);

  const router = useRouter();
  const scriptRef = useRef<HTMLDivElement>(null);
  const headerProps = Component.header || {};
  const viewportMeta =
    useOs() === 'ios'
      ? 'minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1'
      : 'minimum-scale=1, initial-scale=1, width=device-width';

  useRouteProgress();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleLoad = () => {
        setTimeout(() => {
          scriptRef.current?.classList.add('opacity-100');
        }, 1000);
        if ((window as any).zE) {
          (window as any).zE('messenger:set', 'zIndex', 1001);
        }
      };

      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, []);

  useEffect(() => {
    const countryFromUrl = new URLSearchParams(location.search).get('country');
    if (countryFromUrl) localStorage.setItem('customerCountry', countryFromUrl.toUpperCase());
  }, []);

  return (
    <main className={`${latoFont.className} ${latoFont.variable}`}>
      <MantineProvider withGlobalStyles withNormalizeCSS={false} theme={themeOverride}>
        <ReactQueryProvider>
          <StaticContentProvider value={content}>
            <Head>
              <meta name="google-site-verification" content="Ur2r7Mv8VdeEoXVA8Nt20XCzt_71GUymsMRFASvM1YA" />
              <meta name="viewport" content={viewportMeta} />
              {/* canonical URL conditions */}
              {!(router.asPath.includes('products') && router.asPath.includes('collections')) && (
                <>
                  <link rel="alternate" hrefLang="x-default" href={`https://www.hellomolly.com${router.asPath}`} />
                  <link
                    rel="alternate"
                    href={`https://www.hellomolly.com${getUpdatedPath(router.asPath, 'US')}`}
                    hrefLang="en-us"
                  />
                  <link
                    rel="alternate"
                    href={`https://www.hellomolly.com.au${getUpdatedPath(router.asPath, 'AU')}`}
                    hrefLang="en-au"
                  />
                  <link
                    rel="alternate"
                    href={`https://www.hellomolly.co.nz${getUpdatedPath(router.asPath, 'NZ')}`}
                    hrefLang="en-nz"
                  />
                  {env.UK_LIVE && (
                    <link
                      rel="alternate"
                      href={`https://www.hellomolly.co.uk${getUpdatedPath(router.asPath, 'GB')}`}
                      hrefLang="en-gb"
                    />
                  )}
                </>
              )}
              {/* canonical URL conditions */}
              {!(router.pathname === '/') &&
                !router.pathname.includes('product') &&
                !router.pathname.includes('collections') && <title>{generateTitle(router)}</title>}
              {router.pathname === '/' && (
                <meta key="title" name="title" content="Fashion For Women | Official Online Store" />
              )}
            </Head>
            {/* cookiebot */}
            <Script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="dc915eac-2b05-4057-942b-dff9f160323b"
              type="text/javascript"
              strategy="beforeInteractive"
              async
            />
            { marketing && <Script
                id="elevar-config"
                strategy="beforeInteractive"
                type="module"
                dangerouslySetInnerHTML={{
                  __html: content['Tag.ElevarConfig'] ?? '',
                }}
              />
            }
            {/* cookie scripts imported here */}
            { setCookiesScripts }
            <StoreLocator />
            <NewsletterDialog />
            {env.FEATURE_MULTICURRENCY && <LocationDialog />}
            <Script async src="https://t.cfjump.com/tag/41444" />
            <Header {...headerProps} />
            <ErrorBoundary errorComponent={Error}>
              <Component {...pageProps} />
            </ErrorBoundary>
            <Footer />
              <div ref={scriptRef} className="opacity-0 transition-opacity duration-500 ease-in-out">
                <Script
                  data-cookieconsent="ignore"
                  src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/${env.NEXT_PUBLIC_YOTPO_APP_KEY}`}
                  crossOrigin="anonymous"
                  strategy="lazyOnload"
                />
                <Script
                  data-cookieconsent="ignore"
                  id="ze-snippet"
                  src="https://static.zdassets.com/ekr/snippet.js?key=27722cd3-e415-466e-ae40-ad5019545d97"
                  onLoad={() => {
                    if ((window as any).zE) {
                      (window as any).zE('messenger:set', 'zIndex', 1001);
                    }
                  }}
                  strategy="lazyOnload"
                />
              </div>
          </StaticContentProvider>
        </ReactQueryProvider>
      </MantineProvider>
      <Toaster />
    </main>
  );
}